import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { Layout, Button } from 'antd';
import useBreakpoints from 'hooks/useBreakpoints';
import styles from './Layout.module.less';
import SidebarMenu from 'components/SidebarMenu';
import SwitchRolesMenu from 'components/SwitchRolesMenu';
import { Menu } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { ROLES } from 'constants';
import { useSelector } from 'react-redux';
import { selectActiveRole } from 'features/auth';

const { Header, Content, Sider } = Layout;

export default function LayoutPage({
  children,
  menuOptions,
  className,
  headerIcons,
  scrollClassName = 'overflow-auto',
}) {
  const activeRole = useSelector(selectActiveRole);
  const isInternalUser = ![ROLES.FAMILY, ROLES.PROVIDER].includes(activeRole);

  const breakpoints = useBreakpoints();
  const { screen } = breakpoints;
  const sidebarOverlay = ['xs', 'sm'].includes(screen);

  const history = useHistory();

  const [collapsed, setCollapsed] = useState(false);

  const sidebarAnimationVariants = {
    open: (height = 1000) => ({
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 20,
        restDelta: 1,
      },
    }),
    closed: {
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 200,
        damping: 40,
      },
    },
  };

  let sidebar = (
    <Sider
      width={261}
      theme="light"
      trigger={null}
      collapsible={!sidebarOverlay}
      data-testid="sidebar"
      className="h-full"
    >
      <SidebarMenu
        menuOptions={[
          ...menuOptions,
          {
            label: 'My account',
            to: '/account',
            match: ['/account'],
            onClick: () => {
              history.push('/account');
            },
          },
        ]}
      />
    </Sider>
  );

  const toggleCollapsed = () => {
    if (localStorage && localStorage.setItem) {
      localStorage.setItem('ccaoa__sidebar-collapsed', collapsed === false ? 'true' : 'false');
    }

    // add sidebar-collapsed to html tag
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const html = document.getElementsByTagName('html')[0];
      if (collapsed === false) {
        html.classList.add('sidebar-collapsed');
      } else {
        html.classList.remove('sidebar-collapsed');
      }
    }

    setCollapsed((v) => !v);
  };

  if (sidebarOverlay) {
    sidebar = (
      <motion.div
        className="sidebar-container"
        animate={!collapsed ? 'open' : 'closed'}
        variants={sidebarAnimationVariants}
        onClick={toggleCollapsed}
      >
        {sidebar}
      </motion.div>
    );
  }

  useEffect(() => {
    if (localStorage && localStorage.getItem) {
      const collapsedPreference = localStorage.getItem('ccaoa__sidebar-collapsed');
      if (collapsedPreference === 'true') {
        setCollapsed(true);
      }

      // add sidebar-collapsed to html tag
      if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const html = document.getElementsByTagName('html')[0];
        if (collapsedPreference === 'true') {
          html.classList.add('sidebar-collapsed');
        } else {
          html.classList.remove('sidebar-collapsed');
        }
      }
    }
  }, []);

  return (
    <Layout className={classNames(styles.layout, 'max-h-screen')}>
      <Header theme="light" className="shadow-sm">
        <Button
          type="link"
          icon={<Menu size={24} />}
          aria-label={collapsed ? 'Expand Sidebar' : 'Collapse Sidebar'}
          className="sidebar-trigger"
          data-testid="sidemenu-trigger"
          onClick={toggleCollapsed}
        ></Button>
        <Link to="/providers" className="home-link block h-[56px]" title="Home">
          <img src="/images/CCAoA-Logo-White.svg" alt="ChildCare Aware of America logo" />
        </Link>
        <motion.div
          className="trigger-padding"
          transition={{ duration: 0.2 }}
          initial={{ x: sidebarOverlay ? 0 : 150 }}
        ></motion.div>

        <div className="space" />

        <div className="right-menu">
          {isInternalUser && (
            <>
              <SwitchRolesMenu />

              <div style={{ width: '.5px', backgroundColor: '#FFFFFF', height: '30%' }}></div>
            </>
          )}

          {headerIcons}
        </div>
      </Header>

      {(activeRole === 'ProviderManager' || activeRole === 'ProviderCoordinator') && (
        <div style={{ backgroundColor: '#1C3563', height: '40px', paddingTop: '12px' }} role="contentinfo">
          <div className="right-menu" style={{ color: '#FFFFFF', fontSize: '14px', marginLeft: '60px' }}>
            Provider services
          </div>
        </div>
      )}

      <Layout className={classNames('root-ant-layout h-[calc(100vh-50px)] overflow-hidden sticky top-0')}>
        {!collapsed ? sidebar : null}

        <Layout className={scrollClassName}>
          <Content
            className={classNames(
              className,
              'block max-w-[1300px] w-full !h-auto min-h-[unset] mt-[30px] mb-[200px] mx-auto px-4',
            )}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

LayoutPage.propTypes = {
  children: PropTypes.any,
  menuOptions: PropTypes.array,
  breadcrumbs: PropTypes.array,
};
