import React, { useCallback, useEffect, useState } from 'react';
import produce from 'immer';
import { message, Input, Button } from 'antd';
import { useAsync, useAsyncCallback } from 'react-async-hook';

import Form from 'components/Form';
import Section from 'components/Section';
import Select, { AsyncSelect } from 'components/Select';
import Actions from 'containers/Family/AddApplication/Actions';
import familyService from 'services/family.service';
import commonService from 'services/common.service';
import { deepClone, getFullName, getPhoneNumberValidator } from 'utils';
import useHasRole from 'hooks/useHasRole';

import { ADD_FAMILY_STEPS, PHONE_TYPES, ROLES } from 'constants/index';
import { BiPlus } from 'react-icons/bi';
import PhoneNumber from 'components/PhoneNumber';
import AddressFields from 'components/AddressFields';
import { APPLICATION_DEMO_DATA } from '../../AddApplication';
import { omit } from 'lodash';
import { MagicWandFilled } from '@carbon/icons-react';

const CREATE_NEW_ID = '__create-new__';

export default function Start({
  id,
  setStep,
  next,
  household,
  application,
  dispatch,
  actions,
  profileData,
  onCancel,
  loading,
}) {
  const isFamily = useHasRole(ROLES.FAMILY);
  const [form] = Form.useForm();
  const applicantId = Form.useWatch(['applicantId'], form);

  // const sponsor = Form.useWatch(['sponsor'], form);
  const programSponsorId = Form.useWatch(['position', 'programSponsorId'], form);
  const militaryComponentId = Form.useWatch(['position', 'militaryComponentId'], form);

  const [adults, setAdults] = useState([]);
  useAsync(async () => {
    if (!application || household?.id === undefined) {
      return;
    }

    try {
      // Fetch all adults in the household
      const _adults = await commonService.get(`/families/households/${household?.id}/adults?military=true`, {
        cache: { interpretHeader: false },
      });
      setAdults(_adults);

      // If the application has an applicant, then set the form values
      const _applicantId = application?.applicant?.id;
      if (_applicantId) {
        const _adult = _adults.find((a) => a.id === _applicantId);
        if (_adult) {
          form.setFieldsValue(_adult);
        }
      }
    } catch (e) {
      console.log('Error fetching adults', e);
      message.error('Error fetching data');
    }
  }, [application, household?.id]);

  const { execute: onAddItem } = useAsyncCallback(
    async (payload) => {
      try {
        payload = { ...APPLICATION_DEMO_DATA[1], ...omit(deepClone(payload), ['applicantId']) };

        const updatedData = await familyService.addUpdateAdult(household?.id, null, [payload]);
        return updatedData;
      } catch (e) {
        message.error('Something went wrong.', 5);
      }
    },
    [household?.id],
  );

  useEffect(() => {
    if (application?.applicant?.id) {
      form.setFieldValue('applicantId', application?.applicant?.id);
    }
    // programTypes dependency is required
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application?.applicant?.id, application?.program?.id]);

  const onSubmit = useCallback(
    async (values) => {
      values = deepClone(values);
      dispatch(actions.setIsFormSubmitting(true));
      try {
        let _applicantId = values?.applicantId;
        let _newSponsorData;

        if (_applicantId === CREATE_NEW_ID) {
          // First add the new SPONSOR
          _newSponsorData = await onAddItem(values);
          _applicantId = _newSponsorData?.[0]?.id;
        } else {
          console.log('Updating adult', values);
          _newSponsorData = await familyService.addUpdateAdult(household.id, _applicantId, values);
        }

        if (isFamily) {
          values = {
            ...values,
            applicant: { ..._newSponsorData, id: _applicantId },
            id,
          };
        } else {
          values = produce({ id: application.id, ...values }, (draft) => {
            draft.applicant = {
              ...(application.applicant || {}),
              ...(values.applicant || {}),
              ..._newSponsorData,
            };

            draft.id = application.id;
          });
        }

        const data = await familyService.saveApplication(values);
        dispatch(actions.setApplication(data));
        next({
          paths: { step: ADD_FAMILY_STEPS.START, id: data.id },
          applicationId: data.id,
        });
      } catch (error) {
        message.error(`Unable to ${application?.applicant?.id ? 'update' : 'create'} Sponsor.`);
        newrelic.noticeError(error);
      } finally {
        dispatch(actions.setIsFormSubmitting(false));
      }
    },
    [actions, application.applicant, application.id, dispatch, household.id, id, isFamily, next, onAddItem],
  );

  return (
    <div className="white-box h-full [&_ol]:list-decimal [&_ul]:list-disc [&_hr]:my-5 [&_h4]:text-sm [&_h4]:font-semibold [&_h4]:uppercase [&_h4]:tracking-widest [&_h4]:text-primary [&_h4]:leading-4 [&_h4]:mb-5 [&_br]:last:hidden">
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        {isFamily && (
          <Section headingClassName="page-title" heading="New Family Application" testId="intro-section">
            <p className="text-sm mb-4">
              <b>Select the Adult</b> that you wish to add to this Application as the Sponsor.
            </p>

            <div className="w-full flex items-center gap-2">
              <Form.Item
                name="applicantId"
                label="Sponsor Name"
                rules={[{ required: true, message: 'Sponsor isrequried.' }]}
                style={{ width: '250px' }}
              >
                <AsyncSelect
                  isClearable={false}
                  apiPrefix="/families"
                  optionsApiUrl={`/households/${household.id}/adults?military=true`}
                  optionFormatter={(opt) => {
                    return [
                      {
                        icon: <BiPlus className="w-4 h-4 align-middle" />,
                        label: 'Create New',
                        id: CREATE_NEW_ID,
                        value: CREATE_NEW_ID,
                      },
                    ].concat(opt);
                  }}
                  getOptionLabel={(opt) => {
                    return opt.id === CREATE_NEW_ID ? opt.label : getFullName(opt);
                  }}
                  isOptionSelected={(opt, [value]) => {
                    return opt.id === value;
                  }}
                  onChangeFormatter={(v) => v?.id}
                  onChange={(_selectedId) => {
                    if (_selectedId !== CREATE_NEW_ID) {
                      const _adult = adults.find((a) => a.id === _selectedId);
                      form.setFieldsValue({
                        ..._adult,
                        applicantId: _adult.id,
                      });
                    } else {
                      form.resetFields();
                      form.setFieldsValue({ applicantId: CREATE_NEW_ID });
                    }
                  }}
                />
              </Form.Item>

              {applicantId === CREATE_NEW_ID && (
                <Button
                  type="primary"
                  className="mt-[2px]"
                  icon={<MagicWandFilled className="align-middle mt-[-1px]" />}
                  onClick={() => {
                    form.setFieldsValue(APPLICATION_DEMO_DATA[1]);
                  }}
                >
                  Fill with Demo Data
                </Button>
              )}
            </div>
          </Section>
        )}

        <Section headingClassName="page-title" heading="Program Eligibility" collapsible={false}>
          <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: 600, gap: 16 }}>
            <Form.Item
              name={['position', 'programSponsorId']}
              label="Branch"
              rules={[{ required: true, message: 'Branch is required' }]}
              style={{ width: '48%' }}
            >
              <AsyncSelect
                apiPrefix="/common"
                optionsApiUrl="/program-sponsors"
                placeholder="Select"
                ariaLabel="Select Branch"
                onChangeFormatter={(v) => v.id}
                isOptionSelected={(option, [value]) => option.id === value}
              />
            </Form.Item>

            {programSponsorId !== undefined && (
              <>
                <Form.Item
                  name={['position', 'militaryComponentId']}
                  label="Component"
                  rules={[{ required: true, message: 'Component is required' }]}
                  style={{ width: '48%' }}
                >
                  <AsyncSelect
                    getOptions={commonService.get}
                    apiPrefix="/common"
                    optionsApiUrl={
                      programSponsorId
                        ? `/program-types/family/components?branchId=${programSponsorId}`
                        : `/program-types/family/components`
                    }
                    placeholder="Select"
                    ariaLabel="Select Component"
                    onChangeFormatter={(v) => v.id}
                    isOptionSelected={(option, [value]) => option.id === value}
                  />
                </Form.Item>

                <Form.Item
                  name={['position', 'militaryStatusId']}
                  label="Status"
                  rules={[{ required: true, message: 'Status is required' }]}
                  style={{ width: '48%' }}
                >
                  <AsyncSelect
                    getOptions={commonService.get}
                    apiPrefix="/common"
                    optionsApiUrl={
                      programSponsorId && militaryComponentId
                        ? `/program-types/family/statuses/by-component-branch?programSponsorId=${programSponsorId}&componentId=${militaryComponentId}`
                        : `/program-types/family/statuses`
                    }
                    placeholder="Select"
                    ariaLabel="Select Status"
                    isOptionSelected={(option, [value]) => option?.id === value}
                    onChangeFormatter={(v) => v?.id}
                  />
                </Form.Item>

                <Form.Item
                  name={['position', 'grade']}
                  label="Military grade"
                  rules={[{ required: true, message: 'Military grade is required' }]}
                  style={{ width: '48%' }}
                >
                  <AsyncSelect
                    getOptions={familyService.getOptions}
                    apiPrefix=""
                    optionsApiUrl="/options/3"
                    placeholder="Grade"
                    ariaLabel="Select Grade"
                  />
                </Form.Item>
              </>
            )}
          </div>
        </Section>

        <Section headingClassName="page-title" heading="Basic Information" collapsible={false}>
          <div className="flex flex-wrap gap-4 w-[900px]">
            <Form.Item
              name="prefix"
              label="Select Prefix"
              rules={[{ required: true, message: 'Prefix is required.' }]}
              isSame={(prev, curr) => prev?.id === curr?.id}
              className="w-[20%]"
            >
              <AsyncSelect
                getOptions={familyService.getOptions}
                apiPrefix=""
                optionsApiUrl="/options/19"
                placeholder="Select..."
                ariaLabel="Select Prefix"
              />
            </Form.Item>

            <Form.Item
              name={['firstName']}
              label="First Name"
              rules={[{ required: true, message: 'First Name is required' }]}
              className="w-[35%]"
            >
              <Input autoComplete="nope" />
            </Form.Item>

            <Form.Item name={['middleName']} label="Middle Name/Initial" className="w-[35%]">
              <Input />
            </Form.Item>

            <Form.Item
              name={['lastName']}
              label="Last Name"
              rules={[{ required: true, message: 'Last Name is required.' }]}
              className="w-[35%]"
            >
              <Input autoComplete="nope" />
            </Form.Item>

            <Form.Item name="suffix" label="Select Suffix" className="w-[20%]">
              <AsyncSelect
                getOptions={familyService.getOptions}
                apiPrefix=""
                optionsApiUrl="/options/20"
                placeholder="Select..."
                ariaLabel="Select Suffix"
              />
            </Form.Item>

            <Form.Item
              name={['maritalStatus']}
              label="Marital Status"
              rules={[{ required: true, message: 'Marital Status is required' }]}
              className="w-[35%]"
            >
              <AsyncSelect
                getOptions={familyService.getOptions}
                apiPrefix=""
                optionsApiUrl="/options/6"
                placeholder="Select..."
                ariaLabel="Marital Status"
              />
            </Form.Item>
          </div>
        </Section>

        <Section headingClassName="page-title" heading="Contact Information" collapsible={false}>
          <div className="flex flex-wrap gap-4 w-[900px]">
            <Form.Item
              name={'email'}
              label="Preferred Email Address"
              rules={[
                { required: true, message: 'Preferred Email is required' },
                { type: 'email', message: 'Preferred Email Address in invalid' },
                // Validation for duplicate email addresses
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const _emailAddress = value || getFieldValue('email');

                    if (
                      (profileData?.adults || []).some(
                        ({ email, id }) => email?.toLowerCase() === _emailAddress?.toLowerCase() && id !== applicantId,
                      )
                    ) {
                      return Promise.reject('This email address is used in an existing profile of an adult');
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
              className="w-[55%]"
            >
              <Input autoComplete="nope" />
            </Form.Item>

            <Form.Item
              validateFirst
              name="workEmail"
              label={'Military Email Address'}
              rules={[
                {
                  type: 'email',
                  message: `${'Secondary Email'} is invalid`,
                },
              ]}
              className="w-[37%]"
            >
              <Input autoComplete="nope" />
            </Form.Item>

            <Form.Item
              name={'phone'}
              label="Preferred Phone Number"
              rules={[
                { required: true, message: 'Preferred Phone Number is required' },
                getPhoneNumberValidator('Invalid Preferred Phone Number!'),
                // Validation for duplicate phone numbers
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const _phoneNumber = value || getFieldValue('phone');

                    if (
                      (profileData?.adults || []).some(
                        ({ phone, id }) =>
                          phone?.replace(/\D+/g, '') === _phoneNumber?.replace(/\D+/g, '') && id !== applicantId,
                      )
                    ) {
                      return Promise.reject('This phone number is used in an existing profile of an adult');
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
              className="w-[32%]"
            >
              <PhoneNumber placeholder />
            </Form.Item>

            <Form.Item name="phoneType" label="Phone Type" className="w-[15%]">
              <Select
                ariaLabel="Preferred Phone Type"
                options={PHONE_TYPES.map((opt) => ({ title: opt, id: opt }))}
                isOptionSelected={(opt, [value]) => opt?.title === value}
                onChangeFormatter={(v) => v?.title}
              />
            </Form.Item>

            <Form.Item
              name="secondaryPhone"
              label="Secondary Phone Number"
              rules={[getPhoneNumberValidator('Invalid Secondary Phone Number!')]}
              className="w-[32%]"
            >
              <PhoneNumber placeholder />
            </Form.Item>

            <Form.Item name="secondaryPhoneType" label="Phone Type" className="w-[15%]">
              <Select
                ariaLabel="Secondary Phone Type"
                options={PHONE_TYPES.map((opt) => ({ title: opt, id: opt }))}
                isOptionSelected={(opt, [value]) => opt?.title === value}
                onChangeFormatter={(v) => v?.title}
              />
            </Form.Item>

            <AddressFields addressKey="homeAddress" className="w-full" />
          </div>
        </Section>

        <Actions testId={`${ADD_FAMILY_STEPS.SPONSOR}`} />
      </Form>
    </div>
  );
}
