import React, { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';
import toLower from 'lodash/toLower';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAsync } from 'react-async-hook';
import { Row, Col, Input, Button, Modal, message, Spin, Divider, Select } from 'antd';

import Form from 'components/Form';
import userService from 'services/users.service';
import commonApi from 'services/common.service';

import styles from './AddUser.module.less';
import { selectUser } from 'features/auth';
import Card from 'components/Card/Card';
import Space, { Spacer } from 'components/Space/Space';
import PageMeta from 'components/PageMeta/PageMeta';

export default function AddUser({ match, self }) {
  const {
    params: { email },
  } = match;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const useInfo = useSelector(selectUser);
  const username = self ? useInfo.email : email;
  const { result: user, loading: userLoading } = useAsync(userService.adminGetUserById, [username]);

  const { result, loading: rolesLoading } = useAsync(commonApi.get, ['/users/admin/roles/internal']);
  const { result: teams, loading: teamsLoading } = useAsync(commonApi.get, ['/users/teams']);
  const teamOptions = teams?.map((t) => ({
    label: t.title,
    value: t.id,
  }));
  const roles = (result || []).map((t) => ({ value: t, label: t }));

  useEffect(() => {
    if (user?.id) {
      form.setFieldsValue({ ...user, email: user.username, teams: user.teams.map((t) => t.id) });
    }
  }, [form, user, teams]);

  const onCancel = useCallback(() => {
    Modal.confirm({
      title: 'Are you sure you want to leave this page?',
      content: 'Any unsaved changes will be lost if you confirm.',
      icon: null,
      onOk() {
        history.push('/admin/users');
      },
      onCancel() {},
    });
  }, [history]);

  const handleSubmit = useCallback(
    async (values) => {
      values = {
        ...values,
        email: toLower(values.email),
      };
      try {
        setLoading(true);
        if (user) {
          await userService.adminUpdateUser({ ...values, id: user.id });
        } else {
          await userService.adminCreateUser({ ...values });
        }
        message.success(`User ${user?.id ? 'updated' : 'created'} successfully!`, 3);
        history.push('/admin/users');
      } catch (error) {
        newrelic.noticeError(error);
        message.error(error.apierror?.message || `Unable to ${user?.id ? 'update' : 'create'} user.`);
      } finally {
        setLoading(false);
      }
    },
    [history, user],
  );

  return (
    <div className={classNames(styles.addUser, 'layout-page')} id="content-layout-container">
      <div id="page-top-placeholder" />

      <Spin spinning={loading || userLoading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          onValuesChange={(changedValues, values) => {
            if ('department' in changedValues && values?.team?.departmentId !== changedValues?.department?.id) {
              form.setFieldsValue({ team: undefined });
            }
          }}
        >
          <PageMeta title={username ? 'Update internal user' : 'Create new internal user'} />

          <Card noBodyPadding title={username ? 'Update internal user' : 'Create new internal user'}>
            <div className="p-6">
              <p className="instructions mb-6">
                Fill in all details below to create a new user. Please note you must use their official Child Care Aware
                email address.
              </p>

              <Row gutter={[20, 0]}>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    name={['firstName']}
                    label="First Name"
                    rules={[{ required: true, message: 'First Name is required.' }]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    name={['lastName']}
                    label="Last Name"
                    rules={[{ required: true, message: 'Last Name is required.' }]}
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[20, 0]}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name={['email']}
                    label="Email"
                    rules={[
                      { required: true, message: 'Email is required' },
                      { type: 'email', message: 'Invalid Email' },
                      {
                        pattern: /.+@usa\.childcareaware\.org$/,
                        message: 'Email must end with @usa.childcareaware.org',
                      },
                    ]}
                    validateFirst
                  >
                    <Input placeholder="Email" disabled={username} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[20, 0]}>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    name="teams"
                    label="teams"
                    rules={[
                      {
                        required: true,
                        message: 'At least one team must be selected.',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Team"
                      ariaLabel="Select Team"
                      name="teams"
                      mode="multiple"
                      options={teamOptions}
                      loading={teamsLoading}
                      className="ant-select-autoheight"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item name={'roles'} label="Roles" rules={[{ required: true, message: 'Roles is required.' }]}>
                    <Select
                      placeholder="Roles"
                      ariaLabel="Select Roles"
                      name="roles"
                      options={roles}
                      loading={rolesLoading}
                      mode="multiple"
                      className="ant-select-autoheight"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <Divider className="m-0" />

            <Space className="p-6">
              <Button onClick={onCancel} disabled={loading}>
                Cancel
              </Button>

              <Spacer />

              <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                Save
              </Button>
            </Space>
          </Card>
        </Form>
      </Spin>
    </div>
  );
}

// const TeamSelect = ({ value, onChange, placeholder, departmentId, disabled }) => {
//   const [options, setOptions] = useState([]);
//   const [allOptions, setAllOptions] = useState([]);
//   useEffect(() => {
//     if (!departmentId && value) {
//       onChange(undefined);
//     }
//   }, [onChange, departmentId, value]);
//   useEffect(() => {
//     getAllTeams();
//     async function getAllTeams() {
//       try {
//         const teams = await commonApi.get('/users/teams');
//         const allOptions = teams.map((team) => {
//           let obj = {};
//           if (team.subTeams.length > 0) {
//             obj = {
//               options: team.subTeams.map((t) => ({
//                 ...t,
//                 label: t.name,
//                 value: t.id,
//                 departmentId: team.departmentId,
//                 nested: true,
//               })),
//             };
//           } else {
//             obj = { value: team.id };
//           }
//           return {
//             ...team,
//             label: team.name,
//             departmentId: team.departmentId,
//             ...obj,
//           };
//         });
//         setAllOptions(allOptions);
//         setOptions(allOptions);
//       } catch (error) {
//         newrelic.noticeError(error);
//       }
//     }
//   }, []);
//   useEffect(() => {
//     setOptions(allOptions.filter((team) => (departmentId ? Number(team.departmentId) === departmentId : false)));
//   }, [allOptions, departmentId]);
//   let selected = undefined;
//   const rootSelected = options
//     .filter((opt) => opt.value)
//     .find((opt) => {
//       return value && opt.value === value?.value;
//     });
//   if (rootSelected) {
//     selected = rootSelected;
//   } else {
//     selected = [].concat(...options.map((opt) => opt.options || [])).find((opt) => {
//       return value && opt.value === value?.value;
//     });
//   }

//   return (
//     <Select
//       key={value}
//       classNamePrefix="select"
//       placeholder={placeholder}
//       isClearable
//       isSearchable
//       value={selected}
//       options={options}
//       onChange={(selected) => {
//         onChange(selected ? selected : undefined);
//       }}
//       isDisabled={disabled}
//       components={{ Option }}
//     />
//   );
// };

// TeamSelect.propTypes = {
//   departmentId: PropTypes.any,
//   disabled: PropTypes.bool,
//   onChange: PropTypes.func,
//   placeholder: PropTypes.string,
//   value: PropTypes.shape({
//     value: PropTypes.any,
//   }),
// };

// const Option = ({ children, ...rest }) => {
//   const nested = rest.data?.nested;
//   return (
//     <components.Option {...rest}>
//       <span className={classNames({ nested })} style={{ marginLeft: nested ? '20px' : 0 }}>
//         {children}
//       </span>
//     </components.Option>
//   );
// };

// Option.propTypes = {
//   children: PropTypes.any,
// };
